// 支付订单倒计时
<template>
  <span class="CountDown">
    <strong>{{ minute }}:{{ second }}</strong>
  </span>
</template>

<script>
export default {
  name: "CountDown",
  data() {
    return {
      minutes: 10,
      seconds: 10,
    };
  },
  props: {
    countTime: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.add();
  },
  methods: {
    num: function(n) {
      return n < 10 ? "0" + n : "" + n;
    },
    add: function() {
      var _this = this;
      var time = window.setInterval(function() {
        if (_this.seconds === 0 && _this.minutes !== 0) {
          _this.seconds = 59;
          _this.minutes -= 1;
        } else if (_this.minutes === 0 && _this.seconds === 0) {
          _this.seconds = 0;
          window.clearInterval(time);
          // 倒计时结束 取消订单
          
        } else {
          _this.seconds -= 1;
        }
      }, 1000);
    },
  },
  watch: {
    countTime: {
      deep: true,
      immediate: true,
      handler: function(newVal) {
        this.minutes = newVal.minute;
        this.seconds = newVal.second;
      },
    },
    second: {
      handler(newVal) {
        this.num(newVal);
      },
    },
    minute: {
      handler(newVal) {
        this.num(newVal);
      },
    },
  },
  computed: {
    second: function() {
      return this.num(this.seconds);
    },
    minute: function() {
      return this.num(this.minutes);
    },
  },
};
</script>

<style lang="less" scoped>
.CountDown {
  color: #FFD303;
}
</style>
